import type { QueryResultField } from '@meterup/graphql';
import { z } from 'zod';

import type {
  AccessPointQueryQuery,
  AccessPointsQueryQuery,
  ApChannelUtilizationByAccessPointQueryQuery,
  ApChannelUtilizationByNetworkQueryQuery,
  ApHealthScoresByAccessPointQueryQuery,
  ObserverQueryQuery,
  ObserversQueryQuery,
  ObserversTargetsQueryQuery,
  ObserversTestsQueryQuery,
  WirelessConnectionEventsByAccessPointQueryQuery,
} from '../../gql/graphql';
import { graphql } from '../../gql';
import {
  UpdateRadioSettingsInputSchema as BaseRadioSettingsSchema,
  UpdateVirtualDeviceInputSchema as BaseSchema,
} from '../../gql/zod-types';

export enum AccessPointsListTab {
  Current = 'list',
  Spares = 'spares',
  Insights = 'insights',
  BSSIDs = 'bssids',
}

export enum ValidAPDrawerTab {
  AccessPoint = 'access-point',
  Config = 'config',
  Clients = 'clients',
}

export enum ValidAPDetailsTab {
  BSSIDs = 'bssids',
  BootHistory = 'boot-history',
  Clients = 'clients',
  ConnectionEvents = 'connection-events',
  Insights = 'insights',
  ScanData = 'scan-data',
}

export enum ValidObserverDetailsTab {
  Tests = 'tests',
  Targets = 'targets',
}

export const DFS_CHANNELS = [
  52, 54, 56, 58, 60, 62, 64, 100, 102, 104, 106, 108, 110, 112, 116, 118, 120, 122, 124, 126, 128,
  132, 134, 136, 138, 140, 142, 144,
];

export type AccessPointsQueryResult = QueryResultField<
  AccessPointsQueryQuery,
  'virtualDevicesForNetwork'
>;

export type ObserversQueryResult = QueryResultField<
  ObserversQueryQuery,
  'virtualDevicesForNetwork'
>;

export type ObserversTargetsQueryResult = QueryResultField<
  ObserversTargetsQueryQuery,
  'virtualDevicesForNetwork'
>;

export type ObserversTestsQueryResult = QueryResultField<
  ObserversTestsQueryQuery,
  'wifiObserverSettingsForNetwork'
>;

export type AccessPointConnectedClient = NonNullable<
  (AccessPointQueryQuery['virtualDevice'] & {
    __typename: 'AccessPointVirtualDevice';
  })['connectedClients']
>[number];

export type ValidUpdateVirtualDeviceParams = z.infer<typeof BaseSchema>;

export type APChannelUtilizationByAccessPointQueryResult =
  NonNullable<ApChannelUtilizationByAccessPointQueryQuery>['channelUtilizationByAP'][number];

export type APChannelUtilizationByNetworkQueryResult =
  NonNullable<ApChannelUtilizationByNetworkQueryQuery>['channelUtilizationByNetwork'][number];

export type APHealthScoresByAccessPointQueryResult =
  NonNullable<ApHealthScoresByAccessPointQueryQuery>['apHealthScores'][number];

const UpdateRadioSettingsInputSchema = BaseRadioSettingsSchema.extend({
  band2_4GPrimaryChannel: z.number().nullish(),
  band2_4GTransmitPowerdBm: z
    .number()
    .refine((val) => val >= 1 && val <= 23, {
      message: 'Must be between 1 and 23',
    })
    .nullish(),
  band5GPrimaryChannel: z.number().nullish(),
  band5GTransmitPowerdBm: z
    .number()
    .refine((val) => val >= 1 && val <= 23, {
      message: 'Must be between 1 and 23',
    })
    .nullish(),
});

export const UpdateAccessPointInputSchema = BaseSchema.omit({
  enableLEDDarkMode: true,
}).extend({
  // Make this required (its nullish in the base schema)
  label: z.string(),
  isConsoleEnabled: z.boolean(),
  areLEDsEnabled: z.boolean(),
  radioSettings: z.lazy(() => UpdateRadioSettingsInputSchema.nullish()),
});
export type ValidAccessPointParams = z.infer<typeof UpdateAccessPointInputSchema>;

export type WirelessConnectionEventByAccessPointQueryResult = NonNullable<
  WirelessConnectionEventsByAccessPointQueryQuery['wirelessClientConnectionEventsByAP']
>[number];

export const WirelessConnectionEventsByAccessPointQuery = graphql(`
  query WirelessConnectionEventsByAccessPointQuery(
    $networkUUID: UUID!
    $virtualDeviceUUID: UUID!
    $filter: ClientMetricsTimeseriesFilterInput!
    $limit: Int
    $offset: Int
  ) {
    wirelessClientConnectionEventsByAP(
      networkUUID: $networkUUID
      apVirtualDeviceUUID: $virtualDeviceUUID
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      timestamp
      band
      eventType
      failedStep
      reasonCode
      sessionDuration
      timeToConnect
      macAddress
      is80211rRoam
      ssid {
        UUID
        ssid
      }
      virtualDevice {
        UUID
        label
      }
      previousVirtualDeviceConnectedTo {
        UUID
        label
      }
    }
  }
`);

export const WirelessClientMetricsByAccessPointQuery = graphql(`
  query WirelessClientMetricsByAccessPointQuery(
    $networkUUID: UUID!
    $virtualDeviceUUID: UUID!
    $filter: ClientMetricsTimeseriesFilterInput!
  ) {
    wirelessClientMetricsByAP(
      apVirtualDeviceUUID: $virtualDeviceUUID
      networkUUID: $networkUUID
      filter: $filter
    ) {
      metadata {
        signal {
          minValue
          maxValue
        }
        noise {
          minValue
          maxValue
        }
        tx {
          minValue
          maxValue
        }
        rx {
          minValue
          maxValue
        }
      }
      values {
        timestamp
        signal
        noise
        txRate
        rxRate
        snr
        txBytes
        rxBytes
        txMulticastBytes
        rxMulticastBytes
        txUnicastBytes
        rxUnicastBytes
        clientCount
      }
    }
  }
`);

export const APChannelUtilizationByNetworkQuery = graphql(`
  query APChannelUtilizationByNetworkQuery(
    $networkUUID: UUID!
    $filter: ChannelUtilizationTimeseriesFilterInput!
  ) {
    channelUtilizationByNetwork(networkUUID: $networkUUID, filter: $filter) {
      band
      virtualDeviceUUID
      values {
        totalUtilization
        timestamp
      }
    }
  }
`);

export const APHealthScoresByAccessPointQuery = graphql(`
  query APHealthScoresByAccessPointQuery($serialNumber: String!, $filter: MetricsFilterInput!) {
    apHealthScores(serialNumber: $serialNumber, filter: $filter) {
      timestamp
      overallScore
      performanceScore
      connectionScore
      rfScore
      systemScore
    }
  }
`);

export const APChannelUtilizationByAccessPointQuery = graphql(`
  query APChannelUtilizationByAccessPointQuery(
    $networkUUID: UUID!
    $virtualDeviceUUID: UUID!
    $filter: ChannelUtilizationTimeseriesFilterInput!
  ) {
    channelUtilizationByAP(
      networkUUID: $networkUUID
      apVirtualDeviceUUID: $virtualDeviceUUID
      filter: $filter
    ) {
      band
      values {
        totalUtilization
        timestamp
      }
    }
  }
`);

export const ObserversQuery = graphql(`
  query ObserversQuery($networkUUID: UUID!, $includeUptime: Boolean = false) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: OBSERVER }) {
      __typename
      UUID
      label
      description
      deviceType
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on ObserverVirtualDevice {
        uptime @include(if: $includeUptime)
        observerSettings {
          SSIDUUID
          targetVirtualDeviceUUID
          band
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }

        ... on AccessPointHardwareDevice {
          ipAddress
          isInCurrentControllerMACTable
          phyInterfaceConnectedTo {
            UUID
            portSpeedMbps
            maxSpeedMbps
            isEthernet
            isSFP
          }
        }
      }
    }
  }
`);

export const ObserverQuery = graphql(`
  query ObserverQuery($uuid: UUID!, $includeUptime: Boolean = false) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      deviceType
      deviceModel
      isConsoleEnabled
      networkUUID
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on ObserverVirtualDevice {
        uptime @include(if: $includeUptime)
        observerSettings {
          SSID {
            UUID
            ssid
          }
          targetVirtualDevice {
            UUID
            label
          }
          band
          durationMsec
          networkUUID
          virtualDevice {
            UUID
            label
          }
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        deviceModel
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }

        ... on AccessPointHardwareDevice {
          ipAddress
          isInCurrentControllerMACTable
          phyInterfaceConnectedTo {
            UUID
            portNumber
            portSpeedMbps
            maxSpeedMbps
            isEthernet
            isSFP
            virtualDevice {
              UUID
              label
            }
          }
          phyInterfaceLastConnectedTo {
            phyInterface {
              UUID
              portNumber
              virtualDevice {
                UUID
                label
              }
            }
            connectedAt
          }
        }
      }
    }
  }
`);

export const ObserversTargetsQuery = graphql(`
  query ObserversTargetsQuery($networkUUID: UUID!, $includeUptime: Boolean = false) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: ACCESS_POINT }) {
      __typename
      UUID
      label
      description
      deviceType
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on AccessPointVirtualDevice {
        uptime @include(if: $includeUptime)
        observerSettings {
          virtualDeviceUUID
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }

        ... on AccessPointHardwareDevice {
          ipAddress
          isInCurrentControllerMACTable
          phyInterfaceConnectedTo {
            UUID
            portSpeedMbps
            maxSpeedMbps
            isEthernet
            isSFP
          }
        }
      }
    }
  }
`);

export const ObserversTestsQuery = graphql(`
  query ObserversTestsQuery($networkUUID: UUID!) {
    wifiObserverSettingsForNetwork(networkUUID: $networkUUID) {
      __typename
      virtualDevice {
        UUID
        label
      }
      targetVirtualDevice {
        UUID
        label
      }
      SSID {
        UUID
        ssid
      }
      band
    }
  }
`);

export const AccessPointsQuery = graphql(`
  query AccessPointsQuery(
    $networkUUID: UUID!
    $includeUptime: Boolean = false
    $includeIsDev: Boolean = false
  ) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: ACCESS_POINT }) {
      __typename
      UUID
      label
      description
      deviceType
      nosUpgradeGroup {
        UUID
      }
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on AccessPointVirtualDevice {
        ...AccessPointUptimeFragment @include(if: $includeUptime)
        latestHealthScores {
          overallScore
          performanceScore
          connectionScore
          rfScore
          systemScore
        }
        radioProfile {
          name
          UUID
          band2_4GIsEnabled
          band2_4GChannelWidthMHz
          band2_4GAutoChannelIsEnabled
          band5GIsEnabled
          band5GChannelWidthMHz
          band5GAutoChannelIsEnabled
        }

        radioSettings {
          band2_4GPrimaryChannel
          band2_4GTransmitPowerdBm
          band5GPrimaryChannel
          band5GTransmitPowerdBm
        }

        connectedClients {
          macAddress
          band
          channel
          ssidName
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
        isDev @include(if: $includeIsDev)

        ... on AccessPointHardwareDevice {
          ipAddress
          isInCurrentControllerMACTable
          phyInterfaceConnectedTo {
            UUID
            portSpeedMbps
            maxSpeedMbps
            isEthernet
            isSFP
          }
        }
      }
    }
  }
`);

graphql(`
  fragment AccessPointUptimeFragment on AccessPointVirtualDevice {
    uptime
  }
`);

export const AccessPointQuery = graphql(`
  query AccessPointQuery($uuid: UUID!, $includeUptime: Boolean = false) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      deviceType
      deviceModel
      isConsoleEnabled
      networkUUID
      nosUpgradeGroup {
        UUID
      }
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }
      ... on AccessPointVirtualDevice {
        isLEDDarkModeEnabled
        ...AccessPointUptimeFragment @include(if: $includeUptime)

        latestHealthScores {
          overallScore
          performanceScore
          connectionScore
          rfScore
          systemScore
        }

        radioProfile {
          UUID
          name
          band2_4GIsEnabled
          band2_4GChannelWidthMHz
          band2_4GAutoChannelIsEnabled
          band5GIsEnabled
          band5GChannelWidthMHz
          band5GAutoChannelIsEnabled
        }

        radioSettings {
          band2_4GPrimaryChannel
          band2_4GTransmitPowerdBm
          band5GPrimaryChannel
          band5GTransmitPowerdBm
        }

        connectedClients {
          macAddress
          band
          channel
          ssidName
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        deviceModel
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }

        ... on AccessPointHardwareDevice {
          ipAddress
          isInCurrentControllerMACTable
          phyInterfaceConnectedTo {
            UUID
            portNumber
            portSpeedMbps
            maxSpeedMbps
            isEthernet
            isSFP
            virtualDevice {
              UUID
              label
            }
          }
          phyInterfaceLastConnectedTo {
            phyInterface {
              UUID
              portNumber
              virtualDevice {
                UUID
                label
              }
            }
            connectedAt
          }
        }
      }
    }
  }
`);

export const LatestDeviceMemoryUsageQuery = graphql(`
  query LatestDeviceMemoryUsage($serialNumber: String!) {
    latestDeviceMemoryUsage(serialNumber: $serialNumber) {
      bytesUsed
      bytesAvailable
    }
  }
`);

export const DeviceUptimeQuery = graphql(`
  query DeviceUptime($serialNumber: String!) {
    deviceUptime(serialNumber: $serialNumber)
  }
`);

export const LatestDeviceCPULoadQuery = graphql(`
  query LatestDeviceCPULoad($serialNumber: String!) {
    latestDeviceCPULoad(serialNumber: $serialNumber) {
      usedPercentage
    }
  }
`);

export const DeviceConfigVersionQuery = graphql(`
  query DeviceConfigVersion($serialNumber: String!) {
    latestDeviceConfig(serialNumber: $serialNumber) {
      version
    }
    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {
      version
      acknowledgedAt
    }
  }
`);

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export const UpdateVirtualDevicesMutation = graphql(`
  mutation UpdateVirtualDevicesMutation(
    $virtualDeviceUUIDs: [UUID!]!
    $networkUUID: UUID!
    $input: UpdateVirtualDeviceInput!
  ) {
    updateVirtualDevices(
      networkUUID: $networkUUID
      virtualDeviceUUIDs: $virtualDeviceUUIDs
      input: $input
    ) {
      UUID
    }
  }
`);

export const fallbackAccessPointHardwareDeviceQuery = graphql(`
  query FallbackAccessPointHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
      virtualDevice {
        label
      }
    }
  }
`);

export const BSSIDsForNetworkQuery = graphql(`
  query BSSIDsForNetworkQuery($networkUUID: UUID!) {
    bssidsForNetwork(networkUUID: $networkUUID) {
      BSSID
      SSID {
        ssid
        isHidden
      }
      isActive
      radioBand
      accessPointHardwareDevice {
        virtualDevice {
          UUID
          label
        }
      }
    }
  }
`);

export type AccessPoint = AccessPointQueryQuery['virtualDevice'] & {
  __typename: 'AccessPointVirtualDevice';
};

export type Observer = ObserverQueryQuery['virtualDevice'] & {
  __typename: 'ObserverVirtualDevice';
};
